import * as React from 'react';
import { IBgImageStripProps } from '../../../BgImageStrip.types';
import BackgroundDivOrImage from '../../Common/BackgroundDivOrImage';
import style from './styles/skins.scss';

const BaseBgImageStrip: React.FC<IBgImageStripProps> = props => {
  const { id, skin, backgroundImage } = props;
  return (
    <div id={id} className={style[skin]}>
      <BackgroundDivOrImage
        bgClass={style.bg}
        backgroundImageProps={backgroundImage}
      />
    </div>
  );
};

export default BaseBgImageStrip;
